import { IonButton } from '@ionic/react';
import React, { useState } from 'react';
import { UserStore } from '../../../store/UserStore';
import AddressPicker from '../../AddressPicker';
import ManualAddress from '../../ManualAddress';
import '../../Page.css';

const Step4 = () => {
    const step = UserStore.useState(s => s.onboardingStep);
    const userDoc = UserStore.useState(s => s.userDoc);
    const [ notFound, setNotFound ] = useState(false);

    return (
      <div className={ step === 4 ? 'steps' : 'ion-hide'}>
        <h2 className="ion-padding-horizontal onboardingTitles">Find your address</h2>
          <div style={{height: '20px'}} />
          {!notFound ? 
          <AddressPicker context="onboarding" />
          : 
          <ManualAddress context="onboarding" />}
          
          <div className="ion-padding-horizontal">
            <p className="ion-text-left"><strong>How will we use your address?</strong></p>
            <p className="ion-text-left">You address will be used as your default permit address. You will be able to change the address on your burn permit applications, if necessary.</p>
          </div>
          <IonButton  
            disabled={!userDoc.address ? true :
              false
            }
            className="ion-margin" 
            shape="round" 
            expand="block"
            onClick={() => {
              UserStore.update(s => {
                s.onboardingStep = 5;
              })
            }}
            >{'Continue'}</IonButton>
            <p className="ion-text-center ion-no-margin"><small>{!notFound ? `Don't see your address listed?` : `Use Google to find my address`}</small></p>
            <p className="ion-text-center ion-no-margin ion-margin-bottom"><IonButton onClick={() => setNotFound(!notFound)} size="small" fill="clear">Click here</IonButton></p>
      </div>
    );
};

export default Step4;
