import Menu from './components/Menu.jsx';
import React, { useEffect } from 'react';
import { IonApp, IonContent, IonLoading, IonPage, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/*Firebase Stuff */
import firebaseApp from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Pullstate Stores */
import { PermitStore } from "./store/PermitStore";
import { UserStore } from "./store/UserStore";
import { TownStore } from "./store/TownStore";
import { AppStore } from "./store/AppStore";

import Home from './pages/Home';
import Account from './pages/Account';
import Login from './pages/Login.jsx';
import Forgot from './pages/Forgot.jsx';
import Register from './pages/Register.jsx';
import Profile from './pages/Profile.jsx';
import NoNetwork from './components/NoNetwork';
import { useStatus } from '@capacitor-community/react-hooks/network';
import { Plugins } from '@capacitor/core';
import { toast } from './components/Toast/Toast.jsx';
import Permits from './pages/Permits.jsx';
import Permit from './pages/Permit.jsx';

const { Network } = Plugins;

const auth = firebaseApp.auth();
const firestore = firebaseApp.firestore();
// const currentUrl = window.location;

export const logout = () => {
  firebaseApp.auth().signOut();
};
const App = () => {
  const [ user, loading, error ] = useAuthState(auth);
  const network = AppStore.useState(s => s.network);
  const { networkStatus } = useStatus();

  // useEffect(() => {
  //     console.log(currentUrl.href);
  //     console.log(currentUrl);
  //     console.log('network =>', networkStatus);
  // }, [networkStatus])

  useEffect(() => {
      const setNetwork = () => {
        if(networkStatus && networkStatus.connected === true) {
          AppStore.update(s => {
            s.network = 'connected';
          });
          // console.log('connected')
          setTimeout(() => {
            toast('Connected', 'success')
          }, 1000)
        }
        if(networkStatus && networkStatus.connected === false) {
          AppStore.update(s => {
            s.network = 'disconnected';
          })
          setTimeout(() => {
            toast('Disconnected', 'danger')
          }, 1000);
        }
      }
      Network.addListener('networkStatusChange', (status) => {
        // console.log("Network status changed", status);
        setNetwork();
      })
      setNetwork();
  }, [network, networkStatus])
  
  
  useEffect(() => {
    // console.log(user);
    if (user) {
      const getUserDoc = () => {
        // console.log(user)
        firestore.collection('users').doc(user.uid)
        .get()
        .then((doc) => {
          if(doc.exists) {
            const data = doc.data();
            const id = doc.id;
            UserStore.update(s => {
              s.userDoc = {id, ...data};
            })
          } 
          if(!doc.exists) {
            firestore.collection('users').doc(user.uid)
            .set({
              firstName: null,
              lastName: null,
              address: null,
              fullAddress: null,
              phone: null,
              email: user.email,
              postal: null
            })
            .then(() => {
              getUserDoc();
            })
          }
        })
        .catch((error) => console.log(error));
      }
      
      getUserDoc();

      UserStore.update(s => {
        s.user = user;
      });

      const getUserPermits = () => {
        // console.log(user)
        firestore.collection('users').doc(user.uid).collection('permits')
        .get()
        .then((querySnapshot) => {
            const permitsList = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              const id = doc.id;
              permitsList.push({id, ...data});
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
            });
            const orderedPermitsList = permitsList.sort(
              function compare( a, b ) {
              if ( b.startDate < a.startDate ){
                return -1;
              }
              if ( b.startDate > a.startDate ){
                return 1;
              }
              return 0;
            })
            PermitStore.update(s => {
              s.permits = orderedPermitsList;
            });
        })
        .catch((error) => console.log(error));
      }
      getUserPermits();
    }
  }, [user]);

  useEffect(() => {
    const getTownDoc = () => {
      // console.log(user)
      firestore.collection('municipality').doc('Whitestone')
      .get()
      .then((doc) => {
        if(doc.exists) {
          const data = doc.data();
          const id = doc.id;
          const terms = data.terms;
          const riskLevel = data.riskLevel;
          const burnBan = data.fireban;
          // console.log(data)
          TownStore.update(s => {
            s.townDoc = {id, ...data};
            s.riskLevel = riskLevel;
            s.burnBan = burnBan;
          });
          PermitStore.update(s => {
            s.terms = terms;
          });
        } else {
          TownStore.update(s => {
            s.townDoc = null;
          });
        }
      })
      .catch((error) => console.log(error));
    }
    const getPermitTypes = () => {
      // console.log(user)
      firestore.collection('municipality').doc('Whitestone').collection('permitTypes')
      .get()
      .then((querySnapshot) => {
        const types = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          // console.log(doc.id, " => ", doc.data());
          types.push({id, ...data})
        });
        PermitStore.update(s => {
          s.permitTypes = types;
        })
      })
      .catch((error) => console.log(error));
    }
    const getAnnouncements = () => {
      firebaseApp.firestore().collection('municipality').doc('Whitestone').collection('announcements').limit(3).get().then((querySnapshot) => {
        const announcementsList = [];
        querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            // doc.data() is never undefined for query doc snapshots
            announcementsList.push({id, ...data})
            // console.log(doc.id, " => ", doc.data());
        });
        const annoucementsSorted = announcementsList.sort((a,b) => (a.dateCreated > b.dateCreated) ? -1 : ((b.dateCreated > a.dateCreated) ? 1 : 0)); 
        TownStore.update(s => {
          s.announcements = annoucementsSorted;
        })
      });
    }
    getTownDoc();
    getPermitTypes();
    getAnnouncements();
  }, [])
  if(network === 'disconnected') {
    return (
      <NoNetwork />
    );
  } 
  if (loading) {
    // console.log('llloooadddding', user)
    return (
      <IonApp>
        <IonPage>
          <IonContent>
            <IonLoading/>
          </IonContent>
        </IonPage>
      </IonApp>
    );
  }
  if (error) {
    return (
      <IonApp>
        <div>
          <p>Error: {error}</p>
        </div>
      </IonApp>
    );
  }
  if (user) {
    return (
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Redirect from="/login" to="/account" exact />
              <Redirect from="/register" to="/account" exact />
              <Redirect from="/home" to="/account" exact />
              <Route path="/account" component={Account} exact />
              <Route path="/permits" component={Permits} exact />
              <Route path="/profile" component={Profile} exact />
              <Route path="/permit/:id" component={Permit} exact />
              <Redirect from="/" to="/account" exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    );
  }
  if (!user && !loading) {
    return (
      <IonApp>
        <IonReactRouter>
          {/* <Menu /> */}
          <IonRouterOutlet id="main">
            <Route path="/home" component={Home} exact />
            <Route path="/login" component={Login} exact />
            <Route path="/forgot" component={Forgot} exact />
            <Route path="/register" component={Register} exact />
            <Redirect from="/account" to="/home" exact />
            <Redirect from="/permit" to="/home" exact />
            <Redirect from="/permits" to="/home" exact />
            <Redirect from="/profile" to="/home" exact />
            <Redirect from="/" to="/home" exact />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }

};

export default App;
