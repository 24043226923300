import { 
  IonContent,
  IonCard,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLoading
} from '@ionic/react';
import { documentOutline } from 'ionicons/icons';
import React, {useState, useEffect, useMemo} from 'react';
// import logo from '../assets/images/logo.jpg';
import { PDFViewer, PDFDownloadLink, Document  } from '@react-pdf/renderer';
import firebaseApp, { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import PermitPDF from '../components/PermitPDF';
import Terms from '../components/Terms';
import { PermitStore } from '../store/PermitStore';


const PDF = (props) => {
  const { permit } = props;
  return useMemo(
    () => (
    <Document title={permit.id} fileName={permit.id} author="FirePermit.Online">
      <PermitPDF permit={permit}/>
      <Terms/>
    </Document>
  ),
  [permit]
  )
}
const PDFRendered = (props) => {

  const {permit} = props;
  return useMemo(
    () => (
      <PDFViewer style={{width: '100%', minHeight: '750px'}}>
        <Document title={permit.id} fileName={permit.id} author="FirePermit.Online">
          <PermitPDF permit={permit} />
          <Terms/>
        </Document>
      </PDFViewer>
    ),
    [permit]
  )
}
const Permit = (props) => {
  const [ user ] = useAuthState(auth);
  const [ permit, setPermit ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ nothing, setNothing ] = useState(false);

  const fbDb = firebaseApp.firestore().collection("users").doc(user.uid).collection("permits");

  useEffect(() => {
    const getPermit = () => {
      if (user !== null) {
        fbDb.doc(props.match.params.id).get().then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              const id = doc.id;
              setPermit({id, ...data})
              // console.log("Document data:", doc.data());
          } else {
              // doc.data() will be undefined in this case
              setNothing(true);
              console.log("No such document!");
          }
          }).catch(function(error) {
              console.log("Error getting document:", error);
          });
      }
    };
    getPermit();
  }, [fbDb, props.match.params.id, user]);

  useEffect(() => {
    // console.log(permit)
  }, [permit])
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500)
  })
  if(nothing) {
    return(
    <IonPage>
      <IonContent>
        <IonGrid className="contentArea">
          <IonCard mode="ios">
            <IonCardHeader className="ion-margin-top">
              <IonCardSubtitle>
                Burn Permit
              </IonCardSubtitle>
              <IonCardTitle>
                Not Found
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonGrid>
      </IonContent>
    </IonPage>
    )
  }
  if (permit) {
  return (
    <IonPage>
      <IonContent>
        <IonGrid className="">
          <IonCard mode="ios">
          <IonCardHeader className="ion-margin-top">
          <IonCardSubtitle>
            Burn Permit
          </IonCardSubtitle>
          <IonCardTitle>
            Application
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
              <IonGrid>
              {isLoading ? 'Loading...' :
                <IonRow>
                  <IonCol>
                    <PDFDownloadLink document={<PDF id={props.match.params.id} permit={permit} user={user} />} fileName={`${props.match.params.id}`}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <IonButton><IonIcon slot="start" icon={documentOutline}/> Download Permit PDF</IonButton>)}
                    </PDFDownloadLink>
                  </IonCol>
                </IonRow>
                }
                <IonRow>
                  <IonCol>
                    <PDFRendered permit={permit}/>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          
        </IonGrid>
      </IonContent>
    </IonPage>
  );
  } else if(!permit) {
    return (
      <IonLoading />
    )
  } 
};

export default Permit;
