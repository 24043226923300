import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLoading, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from "../store/UserStore";
import { TownStore } from '../store/TownStore';
import ApplicationModal from '../components/Application/ApplicationModal';
import Onboarding from '../components/Onboarding/Onboarding';
import BurnBan from '../components/BurnBan';
import Start from '../components/Start';
import { PermitStore } from '../store/PermitStore';
import dayjs from 'dayjs';
import { documentTextOutline } from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import headerIcon from '../assets/images/headerIcon.png';

const Permits = () => {
  let history = useHistory();
  const userDoc = UserStore.useState(s => s.userDoc);
  // const riskLevel = TownStore.useState(s => s.riskLevel);
  const burnBan = TownStore.useState(s => s.burnBan);
  const permits = PermitStore.useState(s => s.permits);
  const [ onboardingFlow, setOnboardingFlow ] = useState(false);

  useEffect(() => {
    // console.log(userDoc);
    if(userDoc && !userDoc.complete) {
      setOnboardingFlow(true);
    }
    // console.log(permits);
  }, [permits, userDoc]);

  if(userDoc) {
    return (
      <IonPage mode="ios">
        <IonHeader className="whiteBar" color="primary">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton /><img src={headerIcon} className="headerIcon" alt="FirePermit.Online" />
            </IonButtons>
            <IonTitle>My Permits</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader  color="primary" collapse="condense">
            <IonToolbar>
              <IonTitle size="large">My Permits</IonTitle>
            </IonToolbar>
          </IonHeader>
          {onboardingFlow ? 
            <Onboarding />
          : burnBan ? 
            <BurnBan />
          :
            <Start />
          }
          <IonGrid className="contentHolder">
            <IonRow>
            {permits.length > 0 && permits.map((per, i) => (
              <IonCol sizeXs="12" sizeMd="6" sizeLg="4" key={i}>
                  <IonCard mode="ios" button onClick={() => history.push(`/permit/${per.id}`)}>
                    {/* <p className="ion-text-center"><IonIcon icon={documentTextOutline} color="primary" className="bigIcon" /></p> */}
                    <p className="ion-text-center"><IonIcon icon={documentTextOutline} color="primary" className="bigIcon" /></p>
                    <IonCardHeader>
                      <IonCardTitle>
                        {per.type}
                      </IonCardTitle>
                      <IonCardSubtitle>
                      {dayjs(per.startDate).format('MMM DD, YYYY')}
                      </IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      {per.address !== 'other' ? per.address: per.otherAddress}
                    </IonCardContent>
                  </IonCard>
              </IonCol>
               ))}
            </IonRow>
          </IonGrid>
          <ApplicationModal />
        </IonContent>
      </IonPage>
    );
  }
  if (!userDoc) {
    return (
      <IonPage>
        <IonLoading isOpen={true}/>
      </IonPage>
    )
  }
};

export default Permits;
