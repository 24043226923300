import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from "../../../store/PermitStore";
import CheckoutForm from '../../CheckoutForm/CheckoutForm';
import pbs from '../../../assets/images/pbs.png'
const Step6 = () => {
    const step = PermitStore.useState(s => s.applicationStep);
    const application = PermitStore.useState(s => s.application);

    return (
        <div className={ step === 6 ? 'steps' : 'ion-hide'}>
            <h2 className="ion-padding-horizontal onboardingTitles">Payment Authorization</h2>
            <div style={{height: '10px'}} />
            <div className="ion-padding-horizontal">
                <p className="ion-padding profileDeets">For your burn permit to be considered active, you must pay the application fee of ${application.price ? application.price : null} in full. Please enter your credit or debit card details below.</p>
            </div>
            <div className="ion-padding">
                <div className="ion-text-center"><CheckoutForm /></div>
            </div>
            <div className="ion-padding">
                <p className="ion-text-center">
                    <img style={{height: '40px', width: 'auto'}} src={pbs} alt="Powered by Stripe" /> 
                </p>
                <p className="ion-text-center">Secure payments powered by Stripe. All major credit/debit cards accepted.</p>
            </div>
        </div>
    );
};

export default Step6;
