import React from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from '../store/UserStore';
import { PermitStore } from '../store/PermitStore';
import { IonInput, IonLabel } from '@ionic/react';

const ManualAddress = (props) => {
    const { context } = props;
    const appAddress = PermitStore.useState((s) => s.application.otherAddress);
    const onAddress = UserStore.useState((s) => s.userDoc.address);
    const setAddress = (fullAddress) => {
        console.log('context', context);
        if (context === 'application') {
            PermitStore.update((s) => {
                s.application = { ...s.application, otherAddress: fullAddress };
            });
        }
        if (context === 'onboarding') {
            UserStore.update((s) => {
                s.userDoc = { ...s.userDoc, address: fullAddress, fullAddress: fullAddress };
            });
        }
    };

    return (
        <div className='ion-padding-horizontal'>
            <IonLabel className='onboardingLabels' position='stacked'>
                Enter Address
            </IonLabel>
            <IonInput
                type='text'
                autocapitalize='on'
                placeholder='Please enter the address here'
                className='onboardingInputs'
                enterkeyhint='next'
                inputmode='text'
                pattern='text'
                value={context === 'application' ? appAddress : onAddress}
                onIonChange={(e) => {
                    setAddress(e.target.value);
                }}
            />
            <p className='ion-text-left'>
                <strong>Please note:</strong> By entering your address manually, your application
                may be subject to additional review by the Fire Department to confirm the location.
            </p>
        </div>
    );
};

export default ManualAddress;
