import React, { useEffect } from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from '../../../store/PermitStore';
import DOMPurify from 'dompurify';
import { IonButton, IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import { UserStore } from '../../../store/UserStore';


const Step1 = () => {
    const step = PermitStore.useState(s => s.applicationStep);
    const terms = PermitStore.useState(s => s.terms);
    const application = PermitStore.useState(s => s.application);
    const { checkedAgree } = application; 
    const userDoc = UserStore.useState(s => s.userDoc);

    useEffect(() => {
        if(application.checkedAgree) {
            PermitStore.update(s => {
                s.application = {
                    ...s.application, 
                    active: true,
                    checkedAgree: true,
                    checkedRead: true,
                    contact: {
                        email: userDoc.email,
                        phone: userDoc.phone
                    },
                    email: userDoc.email,
                    phone: userDoc.phone,
                    firstName: userDoc.firstName,
                    lastName: userDoc.lastName,
                    latitude: userDoc.homeLatitude,
                    longitude: userDoc.homeLongitude,
                    user: userDoc.id,
                    startDate: Date.now(),
                    endDate: null,
                    from: Date.now(),
                    to: null,
                    details: null,
                };
            });
        }
    }, [application.checkedAgree, userDoc.email, userDoc.firstName, userDoc.homeLatitude, userDoc.homeLongitude, userDoc.id, userDoc.lastName, userDoc.phone]);

    // useEffect(() => {
    //     console.log(application)
    // }, [application]);

    function createMarkup() {
        var clean = DOMPurify.sanitize(terms);
        // console.log(clean);
        return {__html: clean};
    }
    
    return (
        <div className={step === 1 ? null : 'ion-hide'}>
            <div className="ion-padding">
                <div dangerouslySetInnerHTML={createMarkup()} />
            </div>
            <IonItem>
                <IonLabel className="ion-text-wrap">I have read and I agree to abide by all of the terms above.</IonLabel>
                <IonCheckbox slot="start" checked={checkedAgree} onIonChange={e => {
                    PermitStore.update(s => {
                        s.application = {...application, checkedAgree: e.target.checked};
                      });
                }} />
            </IonItem>
            <IonButton  
                disabled={!application.checkedAgree}
                className="ion-margin" 
                shape="round" 
                expand="block"
                onClick={() => {
                    PermitStore.update(s => {
                      s.applicationStep = 2;
                    })
                  }}
                >Continue</IonButton>
        </div>
    );
};

export default Step1;
