import React, { useEffect } from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from "../../../store/PermitStore";
import { Checkmark } from 'react-checkmark';
import { IonButton } from '@ionic/react';

const Step6 = () => {
    const step = PermitStore.useState(s => s.applicationStep);
 
    useEffect(() => {
        PermitStore.update(s => {
            s.application = {
                ...s.application, 
                checkedAgree: false,
                checkedRead: false,
                contact: {
                    email: null,
                    phone: null
                },
                email: null,
                phone: null,
                firstName: null,
                lastName: null,
                latitude: null,
                longitude: null,
                user: null,
                startDate: null,
                endDate: null,
                from: null,
                to: null,
                details: null,
            };
        });
    }, [])
    return (
        <div className={ step === 7 ? 'steps' : 'ion-hide'}>
            <>
                <h2 className="ion-padding-horizontal onboardingTitles ion-text-center">Your permit application was successful!</h2>
                <p className="ion-text-center"><Checkmark color="#2dd36f" size='xxLarge' /></p>
                <p className="ion-text-center"><IonButton shape="round" color="success" href="/permits">See My Permits</IonButton></p>
            </>
        </div>
    );
};

export default Step6;
